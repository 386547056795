import { useEffect } from 'react';

interface PageProps {
  title: string;
  children?: any;
}

const Page: React.FC<PageProps> = ({ title, children }) => {
  useEffect(() => {
    document.title = `Certchain | ${title}` || 'Certchain';
  }, [title]);

  return children;
};

export default Page;
