import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import Background from '../assets/backgrounds/AppBar.png';

const drawerWidth: number = 242;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobileSize',
})<AppBarProps & { open: boolean; isMobileSize: boolean }>(
  ({ theme, open, isMobileSize }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open &&
      !isMobileSize && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
  })
);

export default AppBar;
