import { useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Stack,
  CircularProgress,
  useTheme,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';

import { ReactComponent as ArrowBack } from '../assets/icons/arrowBack.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as Widgets } from '../assets/icons/widgets.svg';
import { ReactComponent as Memory } from '../assets/icons/memory.svg';
import { useAppDispatch, useAppSelector } from '../store';
import { setCurrentCase } from '../store/case';

const CaseDetails = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { currentCase } = useAppSelector((state) => state.case);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentCase) {
      setLoading(true);
      setTimeout(() => setLoading(false), 1000);
    }
  }, [currentCase]);

  if (!currentCase) {
    return null;
  }

  return (
    <>
      <Toolbar sx={{ p: 2 }}>
        <Stack direction="column" gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <IconButton
              onClick={() => dispatch(setCurrentCase(undefined))}
              disabled={loading}
            >
              <ArrowBack color="rgba(0, 0, 0, 0.6)" width={24} height={24} />
            </IconButton>
            <Widgets
              color={theme.palette.primary.main}
              width={24}
              height={24}
            />
            <Typography
              variant="caption"
              color="primary"
              textTransform="uppercase"
            >
              Golden thread audit timeline
            </Typography>
          </Stack>
          <Typography variant="h6">{currentCase.name}</Typography>
          <Typography variant="subtitle2">
            Supervisor Passport Balfour Beatty (BB)
          </Typography>
        </Stack>
      </Toolbar>
      <Divider />
      {loading ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          paddingTop={20}
        >
          <Memory color="action" width={24} height={24} />
          <Typography variant="body1">Certchain AI is querying</Typography>
          <CircularProgress />
          <Typography variant="caption">50%</Typography>
        </Stack>
      ) : (
        <Box sx={{ height: 'calc(100vh - 207px)', overflow: 'auto' }}>
          <Stack direction="column" gap={2} sx={{ p: 2 }}>
            <Typography variant="caption">2 Dec 23</Typography>
            <Timeline
              sx={{
                p: 0,
                m: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Francis Golder and 5 others notified of automated action
                    </Typography>
                    <Typography variant="caption">
                      Automated action - Certchain Golden Fred AI
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">Site access blocked</Typography>
                    <Typography variant="caption">
                      Automated action - Certchain Golden Fred AI
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Queried Balfour Beatty's internal policy database
                    </Typography>
                    <Typography variant="caption">
                      Automated action - Certchain Golden Fred AI
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Compliance notifications sent
                    </Typography>
                    <Typography variant="caption">
                      Automated action - Certchain Golden Fred AI
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: 'error.main',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Error />
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Supervisor passport has expired
                    </Typography>
                    <Typography variant="caption">
                      Alert fomr Certchain's data verifier network (DVN)
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            </Timeline>

            <Typography variant="caption">2 Sep 23</Typography>
            <Timeline
              sx={{
                p: 0,
                m: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Compliance alerts sent
                    </Typography>
                    <Typography variant="caption">
                      Automated action by Golden Fred AI (Certchain)
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: 'warning.main',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Warning />
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Supervisor passport has expired
                    </Typography>
                    <Typography variant="caption">
                      Alert fomr Certchain's data verifier network (DVN)
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            </Timeline>

            <Typography variant="caption">2 Dec 23</Typography>
            <Timeline
              sx={{
                p: 0,
                m: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Compliance alerts sent
                    </Typography>
                    <Typography variant="caption">
                      Automated action by Golden Fred AI (Certchain)
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator sx={{ pl: 1 }}>
                  <TimelineDot />
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Supervisor training missed
                    </Typography>
                    <Typography variant="caption">
                      Aaron Johnson via Balfour Beatty Academy
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            </Timeline>

            <Typography variant="caption">2 Sep 23</Typography>
            <Timeline
              sx={{
                p: 0,
                m: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator sx={{ pl: 1 }}>
                  <TimelineDot />
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Supervisor training missed
                    </Typography>
                    <Typography variant="caption">
                      Aaron Johnson via Balfour Beatty Academy
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      width: '30px',
                      height: '30px',
                      bgcolor: '#23a4a9',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="caption" sx={{ lineHeight: 1 }}>
                      AI
                    </Typography>
                  </TimelineDot>
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Compliance alerts sent
                    </Typography>
                    <Typography variant="caption">
                      Automated action by Golden Fred AI (Certchain)
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator sx={{ pl: 1 }}>
                  <TimelineDot />
                  <TimelineConnector sx={{ height: '37px' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack direction="column" gap={0.5}>
                    <Typography variant="body1">
                      Supervisor training missed
                    </Typography>
                    <Typography variant="caption">
                      Aaron Johnson via Balfour Beatty Academy
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default CaseDetails;
