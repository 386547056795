import { Typography, Box, Chip, Button } from '@mui/material';

import Project from '../interfaces/Project';
import ProjectStatus from '../enums/ProjectStatus';

import { ReactComponent as CheckCircle } from '../assets/icons/checkCircle.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';

interface ProjectStatusChipProps {
  project: Project;
}

const ProjectStatusChip: React.FC<ProjectStatusChipProps> = ({ project }) => {
  const renderProjectStatus = () => {
    switch (project.status) {
      case ProjectStatus.NEW:
        return (
          <Chip
            sx={{
              height: '24px',
              minWidth: '92px',
              backgroundColor: '#e0e0e0',
              span: {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            }}
            label={
              <Typography fontSize={13} fontWeight={400} fontFamily="Roboto">
                New project - no status
              </Typography>
            }
          />
        );
      case ProjectStatus.COMPLIANT:
        return (
          <Chip
            sx={{
              backgroundColor: '#4caf50',
              height: '24px',
              minWidth: '92px',
              span: {
                paddingLeft: '1.5px',
                paddingRight: '8px',
              },
            }}
            label={
              <Box display="flex" alignItems="center">
                <CheckCircle color="#FFF" width={15} height={15} />
                <Typography
                  marginLeft="5px"
                  color="#FFF"
                  fontSize={13}
                  fontWeight={400}
                  fontFamily="Roboto"
                >
                  Compliant
                </Typography>
              </Box>
            }
          />
        );
      case ProjectStatus.RISK:
        return (
          <Chip
            sx={{
              backgroundColor: '#ed6c02',
              height: '24px',
              minWidth: '92px',
              span: {
                paddingLeft: '1.5px',
                paddingRight: '8px',
              },
            }}
            label={
              <Box display="flex" alignItems="center">
                <Warning color="#FFF" width={15} height={15} />
                <Typography
                  marginLeft="5px"
                  color="#FFF"
                  fontSize={13}
                  fontWeight={400}
                  fontFamily="Roboto"
                >
                  At risk ({project.riskLevel})
                </Typography>
              </Box>
            }
          />
        );
      case ProjectStatus.NOT_VERIFIED:
        return (
          <Chip
            sx={{
              backgroundColor: '#d32f2f',
              height: '24px',
              minWidth: '92px',
              span: {
                paddingLeft: '1.5px',
                paddingRight: '8px',
              },
            }}
            label={
              <Box display="flex" alignItems="center">
                <Info color="#FFF" width={15} height={15} />
                <Typography
                  marginLeft="5px"
                  color="#FFF"
                  fontSize={13}
                  fontWeight={400}
                  fontFamily="Roboto"
                >
                  Not verified for occupancy
                </Typography>
              </Box>
            }
          />
        );
      default:
        return null;
    }
  };

  return renderProjectStatus();
};

export default ProjectStatusChip;
