import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from './index';
import Project from '../interfaces/Project';
import projectList from '../data/Project.json';
import ProjectStatus from '../enums/ProjectStatus';

interface ProjectState {
  projectList: Project[];
  currentProject: Project;
}

const initialState: ProjectState = {
  projectList: projectList.slice(1) as Project[],
  currentProject: projectList[0] as Project,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addProject: (state, action: PayloadAction<Project>) => {
      state.projectList.unshift(action.payload);
    },
    changeCurrentProjectStatus: (
      state,
      action: PayloadAction<ProjectStatus>
    ) => {
      state.currentProject.status = action.payload;
    },
  },
});

export const { addProject, changeCurrentProjectStatus } = projectSlice.actions;

export default projectSlice.reducer;
