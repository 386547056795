//@ts-nocheck

import { useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@mui/material';

import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';

import BalfourBeatty from '../assets/icons/balfour-beatty.png';
import { ReactComponent as Apartment } from '../assets/icons/apartment.svg';
import { ReactComponent as People } from '../assets/icons/people.svg';
import { ReactComponent as Supplier } from '../assets/icons/supplier.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as Logout } from '../assets/icons/logout.svg';
import { ReactComponent as Notifications } from '../assets/icons/notifications.svg';
import { ReactComponent as ChevronLeft } from '../assets/icons/chevronLeft.svg';
import { ReactComponent as Menu } from '../assets/icons/menu.svg';

const selectedStyle = {
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
};

const MainListItems = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  return (
    <>
      <ListItemButton
        to="/portfolio"
        component={NavLink}
        disableRipple
        selected={pathname.includes('portfolio')}
        sx={selectedStyle}
      >
        <ListItemIcon>
          <Apartment
            color={theme.palette.primary.main}
            width={24}
            height={24}
          />
        </ListItemIcon>
        <ListItemText primary="Portfolio" />
      </ListItemButton>
      <ListItemButton
        disableRipple
        selected={pathname.includes('people')}
        sx={selectedStyle}
      >
        <ListItemIcon>
          <People color={theme.palette.primary.main} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="People" />
      </ListItemButton>
      <ListItemButton
        disableRipple
        selected={pathname.includes('suppliers')}
        sx={selectedStyle}
      >
        <ListItemIcon>
          <Supplier color={theme.palette.primary.main} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Suppliers" />
      </ListItemButton>
      <ListItemButton
        disableRipple
        selected={pathname.includes('settings')}
        sx={selectedStyle}
      >
        <ListItemIcon>
          <Settings color={theme.palette.primary.main} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
    </>
  );
};

const SecondaryListItems = () => {
  const theme = useTheme();

  return (
    <>
      <ListItemButton disableRipple sx={selectedStyle}>
        <ListItemIcon>
          <Logout color={theme.palette.primary.main} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </>
  );
};

const DashboardMainLayout = () => {
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} isMobileSize={isMobileSize}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            disableRipple
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && !isMobileSize && { display: 'none' }),
            }}
          >
            <Menu color="white" width={24} height={24} />
          </IconButton>
          <Typography
            component="h1"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={500}
            lineHeight={1.6}
            letterSpacing={0.15}
            color="#FFF"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Portfolio
          </Typography>
          <Box display="flex">
            <IconButton color="inherit">
              <Badge
                badgeContent={1}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#f44336',
                  },
                }}
              >
                <Notifications color="white" width={24} height={24} />
              </Badge>
            </IconButton>
            <Box marginLeft="40px" display="flex">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                marginRight="16px"
                sx={{ display: { xs: 'none', sm: 'flex' } }}
              >
                <Typography
                  fontSize={16}
                  fontFamily="Roboto"
                  color="#FFF"
                  letterSpacing={0.15}
                  fontWeight={400}
                >
                  Francis Golden
                </Typography>
                <Typography
                  fontSize={12}
                  fontFamily="Roboto"
                  color="#FFF"
                  letterSpacing={0.4}
                  fontWeight={400}
                >
                  Director
                </Typography>
              </Box>
              <Avatar
                alt="Francis Golden"
                src="https://www.himalmag.com/wp-content/uploads/2019/07/sample-profile-picture.png"
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        variant={isMobileSize ? 'temporary' : 'permanent'}
        open={open}
        elevation={24}
        onClose={toggleDrawer}
        isMobileSize={isMobileSize}
      >
        <Toolbar
          sx={{
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component="img"
            src={BalfourBeatty}
            width={40}
            height={40}
            onClick={() => {
              if (window.Captivate) {
                window.Captivate.closeWidget();
              }
              navigate('/portfolio');
            }}
            sx={{ cursor: 'pointer' }}
          />
          {/* <Box component="img" src={Certchain} width={136} height={34} /> */}
          <IconButton disableRipple onClick={toggleDrawer}>
            <ChevronLeft color="rgba(0, 0, 0, 0.54)" width={24} height={24} />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems />
          <Divider sx={{ my: 1 }} />
          <SecondaryListItems />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? '#eef5f5'
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardMainLayout;
