//@ts-nocheck

import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Tooltip,
  Paper,
  IconButton,
  InputBase,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Chip,
  useTheme,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';

import { ReactComponent as SupplierIcon } from '../assets/icons/supplier.svg';
import { ReactComponent as Filter } from '../assets/icons/filter.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as Add } from '../assets/icons/add.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as WorkforceIcon } from '../assets/icons/workforce.svg';
import { ReactComponent as Cancel } from '../assets/icons/cancel.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/checkCircle.svg';

import supplierList from '../data/Supplier.json';
import { useAppDispatch, useAppSelector } from '../store';
import { changeAppState } from '../store/app';

const Supplier = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { appState } = useAppSelector((state) => state.app);
  const [currentView, setCurrentView] = useState('initial');

  const assignColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: 1,
      renderCell(params) {
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{ borderRadius: '4px' }}
                src={
                  params.row.avatar ||
                  `https://ui-avatars.com/api/?background=${theme.palette.primary.main.slice(
                    1
                  )}&color=fff&rounded=true&name=${params.row.name}`
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  fontSize={16}
                  fontFamily="Roboto"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  {params.row.name}
                </Typography>
              }
              secondary={
                <Typography
                  fontSize={12}
                  fontFamily="Roboto"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  {params.row.certchainID}
                </Typography>
              }
            />
          </ListItem>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Supplier Status',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Chip
            sx={{
              backgroundColor: '#4caf50',
              height: '24px',
              minWidth: '109px',
              padding: 0,
            }}
            label={
              <Box display="flex" alignItems="center">
                <CheckCircle color="white" width={15} height={15} />
                <Typography
                  marginLeft="5.5px"
                  component="span"
                  fontSize={13}
                  fontWeight={400}
                  color="white"
                >
                  Verified
                </Typography>
              </Box>
            }
          />
        );
      },
    },
    {
      field: 'peopleStatus',
      headerName: 'People Status',
      sortable: false,
      flex: 1,
      renderCell(params) {
        return (
          <Chip
            sx={{
              backgroundColor: '#4caf50',
              height: '24px',
              minWidth: '109px',
              padding: 0,
            }}
            label={
              <Box display="flex" alignItems="center">
                <CheckCircle color="white" width={15} height={15} />
                <Typography
                  marginLeft="5.5px"
                  component="span"
                  fontSize={13}
                  fontWeight={400}
                  color="white"
                >
                  Verified ({params.row.supervisorCount})
                </Typography>
              </Box>
            }
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      getActions: () => [
        <GridActionsCellItem
          icon={
            <WorkforceIcon color="rgba(0, 0, 0, 0.6)" height={20} width={20} />
          }
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<Cancel color="rgba(0, 0, 0, 0.6)" height={20} width={20} />}
          label="Cancel"
        />,
      ],
    },
  ];

  useEffect(() => {
    if (window.Captivate) {
      if (appState === 'part1') {
        window.Captivate.sendActionToBot({
          id: 'display_supplier_tab_success',
          loader: true,
        });
      }
    }
  }, [appState]);

  useEffect(() => {
    window.Captivate.onActionReceived((response: any) => {
      console.log('response', response);
      switch (response[0].title) {
        case 'display_suppliers_tab_search':
          setCurrentView('assign');

          enqueueSnackbar(
            'HW Contractors and 15 supvervisors have been assigned',
            {
              variant: 'success',
              style: {
                backgroundColor: '#ebf2eb',
                color: '#1e4620',
              },
            }
          );
          enqueueSnackbar(
            'Ackrington Chambers and 21 supvervisors have been assigned',
            {
              variant: 'success',
              style: {
                backgroundColor: '#ebf2eb',
                color: '#1e4620',
              },
            }
          );

          window.Captivate.sendActionToBot({
            id: 'SupplyChainAssignmentSuccess',
            loader: true,
          });

          dispatch(changeAppState('part2'));
          break;
        default:
          break;
      }
    });
  }, [dispatch]);

  const renderInitialScreen = () => (
    <Box
      height="calc(100% - 94px)"
      sx={{ flexGrow: 1, backgroundColor: '#f1f3f3' }}
    >
      <Box
        width="80%"
        margin="auto"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SupplierIcon color="rgba(0, 0, 0, 0.26)" width={72} height={72} />
        <Typography
          textAlign="center"
          marginTop="16px"
          marginBottom="16px"
          fontSize={16}
          fontWeight={400}
          color="rgba(0, 0, 0, 0.6)"
        >
          Assign suppliers to monitor their organisational standards and
          workforce competence
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#00a4a9',
          }}
          disableRipple
        >
          <Typography
            fontSize={14}
            fontWeight={500}
            color="#fafafa"
            marginRight="10px"
          >
            NEW SUPPLIER
          </Typography>
          <SupplierIcon color="white" width={20} height={20} />
        </Button>
      </Box>
    </Box>
  );

  const renderAssignScreen = () => (
    <Box
      minHeight="calc(100% - 94px)"
      padding="34px 0px"
      sx={{ flexGrow: 1, backgroundColor: '#eef5f5' }}
    >
      <Container
        maxWidth={'lg'}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="26px"
        >
          <Box display="flex" alignItems="center">
            <Typography
              fontSize={20}
              fontFamily="Roboto"
              fontWeight={700}
              marginRight="8px"
            >
              Manage 3 suppliers added to this project
            </Typography>
            <Tooltip title="Show info here">
              <Info color="rgba(0, 0, 0, 0.54)" width={20} height={20} />
            </Tooltip>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#00a4a9',
            }}
            disableRipple
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              color="#fafafa"
              marginRight="10px"
            >
              ASSIGN SUPPLIERS
            </Typography>
            <Add color="white" width={12} height={12} />
          </Button>
        </Box>
        <Paper
          component="div"
          sx={{
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingTop: '16px',
            paddingBottom: '16px',
            marginBottom: '49px',
          }}
        >
          <Box
            maxWidth="573px"
            width="100%"
            margin="16px 0px 16px 16px"
            padding="8px 14px"
            height="40px"
            sx={{
              border: 'solid 1px rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              type="button"
              sx={{ p: '0px' }}
              aria-label="search"
              disabled
            >
              <Search color="rgba(0, 0, 0, 0.54)" width={24} height={24} />
            </IconButton>
            <InputBase
              placeholder="Search by Name, Job Role, Supplier, or Certchain ID"
              sx={{ ml: 1, flex: 1 }}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            marginLeft="16px"
            marginBottom="16px"
          >
            <Filter color="rgba(0, 0, 0, 0.87)" width={24} height={24} />
            <Typography marginLeft="11px" fontSize={16} fontWeight={400}>
              Add filters
            </Typography>
          </Box>
          <DataGrid
            sx={{ border: 0, flex: 1, display: 'block', marginBottom: '16px' }}
            getRowHeight={() => 'auto'}
            rows={supplierList}
            columns={assignColumns}
            rowSelectionModel={supplierList.map((r) => r.id)}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </Paper>
      </Container>
    </Box>
  );

  return currentView === 'assign'
    ? renderAssignScreen()
    : renderInitialScreen();
};

export default Supplier;
