import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import DashboardMainLayout from './layouts/DashboardMain';
import DashboardProjectLayout from './layouts/DashboardProject';

import Portfolio from './pages/Portfolio';
import Workforce from './pages/Workforce';
import Compliance from './pages/Compliance';
import Supplier from './pages/Supplier';

import Page from './components/Page';

import { ReactComponent as CheckCircle } from './assets/icons/checkCircle.svg';

import store from './store';

const router = createBrowserRouter([
  {
    path: '/',
    element: <DashboardMainLayout />,
    children: [
      {
        index: true,
        path: '/portfolio',
        element: (
          <Page title="Portfolio">
            <Portfolio />
          </Page>
        ),
      },
      {
        path: '/',
        element: <Navigate to="/portfolio" replace={true} />,
      },
    ],
  },
  {
    path: '/project',
    element: <DashboardProjectLayout />,
    children: [
      {
        path: ':projectId/workforce',
        element: (
          <Page title="Workforce">
            <Workforce />
          </Page>
        ),
      },
      {
        path: ':projectId/compliance/:caseStatus?',
        element: (
          <Page title="Compliance">
            <Compliance />
          </Page>
        ),
      },
      {
        path: ':projectId/supplier',
        element: (
          <Page title="Supplier">
            <Supplier />
          </Page>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle
              color="#1e4620"
              width={20}
              height={20}
              style={{ marginRight: '14px' }}
            />
          ),
        }}
      />
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
