//@ts-nocheck

import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import CaseStatus from '../enums/CaseStatus';
import Case from '../interfaces/Case';
import CaseList from '../components/CaseList';
import CaseDetails from '../components/CaseDetails';
import { useAppDispatch, useAppSelector } from '../store';
import CaseEscalation from '../components/CaseEscalation';
import { addCase, setCurrentCase } from '../store/case';

import caseData from '../data/Case.json';
import { enqueueSnackbar } from 'notistack';

const Compliance = () => {
  const { projectId, caseStatus } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { caseList, currentCase } = useAppSelector((state) => state.case);
  const { appState } = useAppSelector((state) => state.app);
  const cases: Case[] = caseList.filter(
    (value) =>
      value.projectId === projectId &&
      (caseStatus === CaseStatus.ALL || value.status === caseStatus)
  );

  const triggerOpenLegalCaseAction = useCallback(() => {
    dispatch(addCase(caseData[0]));
    enqueueSnackbar(
      `Case '${caseData[0].name}' has been opened and resolveer groups notified`,
      {
        variant: 'success',
        style: {
          backgroundColor: '#ebf2eb',
          color: '#1e4620',
        },
      }
    );
  }, [dispatch]);

  // Check if it has caseStatus or caseStatus is within enums
  useEffect(() => {
    if (
      !caseStatus ||
      (caseStatus &&
        !(Object.values(CaseStatus) as string[]).includes(caseStatus ?? ''))
    ) {
      navigate(`/project/${projectId}/compliance/all`);
    }
  }, [caseStatus, projectId, navigate]);

  useEffect(() => {
    if (appState === 'part2') {
      if (window.Captivate) {
        window.Captivate.sendActionToBot({
          id: 'QueryGoldenThreadSuccess',
          loader: true,
        });
      }
    }
  }, [appState, dispatch]);

  useEffect(() => {
    window.Captivate.onActionReceived((response: any) => {
      console.log('response', response);
      switch (response[0].title) {
        case 'OpenLegalCase':
          setTimeout(triggerOpenLegalCaseAction, 1500);
          break;
        case 'RedirectToLegalCase':
          dispatch(setCurrentCase(caseData[0]!));
          break;
        default:
          break;
      }
    });
  }, [dispatch, triggerOpenLegalCaseAction]);

  if (!caseStatus) {
    return null;
  }

  return (
    <Box
      sx={{ display: 'flex', height: 'calc(100% - 94px)', overflow: 'hidden' }}
    >
      <Paper sx={{ width: 540, display: 'flex', flexDirection: 'column' }}>
        <CaseList caseStatus={caseStatus as CaseStatus} cases={cases} />
      </Paper>
      <Box height="100%" display="flex" flexDirection="column" sx={{ flex: 1 }}>
        {currentCase?.escalated ? <CaseEscalation /> : <CaseDetails />}
      </Box>
    </Box>
  );
};

export default Compliance;
