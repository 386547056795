import {
  List,
  ListSubheader,
  Stack,
  Typography,
  Box,
  ListItemButton,
  Badge,
  Avatar,
  useTheme,
} from '@mui/material';

import Case from '../interfaces/Case';
import CaseStatus from '../enums/CaseStatus';

import { ReactComponent as Article } from '../assets/icons/article.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/checkCircle.svg';
import { ReactComponent as Workforce } from '../assets/icons/workforce.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { useAppDispatch, useAppSelector } from '../store';
import { setCurrentCase } from '../store/case';

type Props = {
  caseStatus: CaseStatus;
  cases: Case[];
};

const CaseList = ({ caseStatus, cases }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { currentCase } = useAppSelector((state) => state.case);

  const selectedStyle = {
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  };

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
      }}
      subheader={
        <ListSubheader
          sx={{
            py: 1,
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            zIndex: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height={75}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Article
                color={theme.palette.primary.main}
                width={24}
                height={24}
              />
              <Typography
                fontSize={20}
                fontWeight={500}
                color="rgba(0, 0, 0, 0.87)"
              >
                <span style={{ textTransform: 'capitalize' }}>
                  {caseStatus.toLowerCase()}
                </span>{' '}
                cases
              </Typography>
            </Stack>
            <Typography
              fontSize={13}
              fontWeight={500}
              color="rgba(0, 0, 0, 0.87)"
            >
              OPEN & RESOLVED
            </Typography>
          </Stack>
        </ListSubheader>
      }
    >
      {cases.map((value) => (
        <ListItemButton
          key={value.id}
          sx={{ py: 2, ...selectedStyle }}
          selected={currentCase && currentCase.id === value.id}
          onClick={() => dispatch(setCurrentCase(value))}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            width="100%"
          >
            <Stack direction="row" gap={2} alignItems="center">
              <Badge
                sx={{ padding: 0 }}
                badgeContent={
                  value.status === CaseStatus.ACTIVE ? (
                    <Error height={12} width={12} />
                  ) : value.status === CaseStatus.EMERGING ? (
                    <Warning height={12} width={12} />
                  ) : (
                    <CheckCircle height={12} width={12} />
                  )
                }
                color={
                  value.status === CaseStatus.ACTIVE
                    ? 'error'
                    : value.status === CaseStatus.EMERGING
                    ? 'warning'
                    : 'success'
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Avatar
                  sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}
                  variant="rounded"
                >
                  <Workforce color="#FFF" width={19.2} height={19.2} />
                </Avatar>
              </Badge>
              <Stack direction="column">
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color="rgba(0, 0, 0, 0.87)"
                >
                  {value.name}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color="rgba(0, 0, 0, 0.6)"
                  variant="caption"
                >
                  Assigned to [Full Name]
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="caption">Just now</Typography>
          </Stack>
        </ListItemButton>
      ))}
    </List>
  );
};

export default CaseList;
