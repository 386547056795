//@ts-nocheck

import { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Box,
  Typography,
  Divider,
  Alert,
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  useTheme,
  ListItemText,
  Chip,
  Tooltip,
  Paper,
  IconButton,
  InputBase,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import workforceList from '../data/Workforce.json';

import { ReactComponent as WorkforceIcon } from '../assets/icons/workforce.svg';
import { ReactComponent as Star } from '../assets/icons/star.svg';
import { ReactComponent as Cancel } from '../assets/icons/cancel.svg';
import { ReactComponent as Add } from '../assets/icons/add.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Filter } from '../assets/icons/filter.svg';
import { useAppDispatch, useAppSelector } from '../store';
import { changeCurrentProjectStatus } from '../store/project';
import ProjectStatus from '../enums/ProjectStatus';

const Workforce = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { appState } = useAppSelector((state) => state.app);

  const [screenView, setScreenView] = useState('initial');
  const [isInitialState, setIsInitialState] = useState(true);

  const initialStateColumns: GridColDef[] = [
    {
      field: 'person',
      headerName: 'Person',
      sortable: false,
      flex: 1,
      renderCell(params) {
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                src={
                  params.row.avatar ||
                  `https://ui-avatars.com/api/?background=${theme.palette.primary.main.slice(
                    1
                  )}&color=fff&rounded=true&name=${params.row.name}`
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  fontSize={16}
                  fontFamily="Roboto"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  {params.row.name}
                </Typography>
              }
              secondary={
                <Typography
                  fontSize={12}
                  fontFamily="Roboto"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  CC-0000
                </Typography>
              }
            />
          </ListItem>
        );
      },
    },
    {
      field: 'permission',
      headerName: 'Permissions',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Typography
            fontSize={14}
            fontWeight={400}
            color="rgba(0, 0, 0, 0.87)"
          >
            Monitor Only
          </Typography>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Chip
            sx={{
              backgroundColor: '#4caf50',
              height: '24px',
              minWidth: '109px',
              padding: 0,
            }}
            label={
              <Box display="flex" alignItems="center">
                <Star color="white" width={15} height={15} />
                <Typography
                  marginLeft="5.5px"
                  component="span"
                  fontSize={13}
                  fontWeight={400}
                  color="white"
                >
                  Verified Role
                </Typography>
              </Box>
            }
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      getActions: () => [
        <GridActionsCellItem
          icon={
            <WorkforceIcon color="rgba(0, 0, 0, 0.6)" height={20} width={20} />
          }
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<Cancel color="rgba(0, 0, 0, 0.6)" height={20} width={20} />}
          label="Cancel"
        />,
      ],
    },
  ];

  const assignColumns: GridColDef[] = [
    {
      field: 'person',
      headerName: 'Person',
      sortable: false,
      flex: 1,
      renderCell(params) {
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                src={
                  params.row.avatar ||
                  `https://ui-avatars.com/api/?background=${theme.palette.primary.main.slice(
                    1
                  )}&color=fff&rounded=true&name=${params.row.name}`
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  fontSize={16}
                  fontFamily="Roboto"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  {params.row.name}
                </Typography>
              }
              secondary={
                <Typography
                  fontSize={12}
                  fontFamily="Roboto"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  CC-0000
                </Typography>
              }
            />
          </ListItem>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Chip
            sx={{
              backgroundColor: '#4caf50',
              height: '24px',
              minWidth: '109px',
              padding: 0,
            }}
            label={
              <Box display="flex" alignItems="center">
                <Star color="white" width={15} height={15} />
                <Typography
                  marginLeft="5.5px"
                  component="span"
                  fontSize={13}
                  fontWeight={400}
                  color="white"
                >
                  Verified Role
                </Typography>
              </Box>
            }
          />
        );
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Typography
            fontSize={14}
            fontWeight={400}
            color="rgba(0, 0, 0, 0.87)"
          >
            Supervisor
          </Typography>
        );
      },
    },
    {
      field: 'balfourBeatty',
      headerName: 'Balfour Beatty',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Typography
            fontSize={14}
            fontWeight={400}
            color="rgba(0, 0, 0, 0.87)"
          >
            Balfour Beatty
          </Typography>
        );
      },
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      sortable: false,
      flex: 1,
      renderCell() {
        return (
          <Typography
            fontSize={14}
            fontWeight={400}
            color="rgba(0, 0, 0, 0.87)"
          >
            Not invited
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      getActions: () => [
        <GridActionsCellItem
          icon={
            <WorkforceIcon color="rgba(0, 0, 0, 0.6)" height={20} width={20} />
          }
          label="Edit"
        />,
        <GridActionsCellItem
          icon={<Cancel color="rgba(0, 0, 0, 0.6)" height={20} width={20} />}
          label="Cancel"
        />,
      ],
    },
  ];

  const triggerAssignProjectSuccessAction = useCallback(() => {
    dispatch(changeCurrentProjectStatus(ProjectStatus.COMPLIANT));
    setScreenView('assign');
    enqueueSnackbar(
      '35 Supervisors have been successfully assigned to this project and invites to join the project in the mobile app sent',
      {
        variant: 'success',
        style: {
          backgroundColor: '#ebf2eb',
          color: '#1e4620',
        },
      }
    );

    setTimeout(() => {
      window.Captivate.sendActionToBot({
        id: 'SupervisorAssignmentSuccess',
        loader: true,
      });
    }, 1500);
  }, [dispatch]);

  const triggerDisplaySuppliersTabAction = useCallback(() => {
    navigate(`/project/${projectId}/supplier`);
  }, [navigate, projectId]);

  useEffect(() => {
    if (isInitialState) {
      setTimeout(() => {
        setIsInitialState(false);

        if (window.Captivate) {
          if (appState === 'part1') {
            window.Captivate.sendActionToBot({
              id: 'open_project_workspace_success_ui',
              loader: true,
            });
          }
        }
      }, 500);
    }
  }, [appState, isInitialState]);

  useEffect(() => {
    window.Captivate.onActionReceived((response: any) => {
      console.log('response', response);
      switch (response[0].title) {
        case 'assigning_people_to_project':
          triggerAssignProjectSuccessAction();
          break;
        case 'display_suppliers_tab':
          triggerDisplaySuppliersTabAction();
          break;
        default:
          break;
      }
    });
  }, [triggerAssignProjectSuccessAction, triggerDisplaySuppliersTabAction]);

  const renderInitialStateScreen = () => (
    <Box
      height="calc(100% - 94px)"
      sx={{ flexGrow: 1, backgroundColor: '#f1f3f3' }}
    >
      <Box
        width="80%"
        margin="auto"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <WorkforceIcon color="rgba(0, 0, 0, 0.26)" width={72} height={72} />
        <Typography
          textAlign="center"
          marginTop="16px"
          marginBottom="16px"
          fontSize={16}
          fontWeight={400}
          color="rgba(0, 0, 0, 0.6)"
        >
          Assign people monitor their competence for the duration of a project
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#00a4a9',
          }}
          disableRipple
        >
          <Typography
            fontSize={14}
            fontWeight={500}
            color="#fafafa"
            marginRight="10px"
          >
            ASSIGN PEOPLE
          </Typography>
          <Add color="white" width={12} height={12} />
        </Button>
      </Box>
    </Box>
  );

  const renderResultsScreen = () => (
    <>
      <Container
        maxWidth={'lg'}
        sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center' }}
      >
        <WorkforceIcon />
        <Typography
          marginLeft="16px"
          fontSize={20}
          fontWeight={500}
          fontFamily="Roboto"
        >
          Results for ‘Supervisors’ in ‘Living Spaces’
        </Typography>
      </Container>
      <Divider />
      <Container maxWidth={'lg'} sx={{ mt: 3, mb: 3 }}>
        <Alert
          sx={{ marginBottom: '16px' }}
          icon={false}
          action={
            <Button color="inherit" size="small">
              DISMISS
            </Button>
          }
        >
          33 supervisors are verified and can be assigned to the project
        </Alert>
        <Alert
          sx={{ marginBottom: '16px' }}
          icon={false}
          severity="error"
          action={
            <Button color="inherit" size="small">
              DISMISS
            </Button>
          }
        >
          1 supervisor have active issues and could not be added
        </Alert>
        <Alert
          sx={{ marginBottom: '16px' }}
          icon={false}
          severity="warning"
          action={
            <Button color="inherit" size="small">
              DISMISS
            </Button>
          }
        >
          1 supervisor has an expiring certification.
        </Alert>

        <DataGrid
          sx={{ marginTop: '8px', border: 0 }}
          rows={workforceList}
          columns={initialStateColumns}
          rowSelectionModel={workforceList.map((r) => r.id)}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </Container>
    </>
  );

  const renderAssignScreen = () => (
    <Box
      minHeight="calc(100% - 94px)"
      padding="34px 0px"
      sx={{ flexGrow: 1, backgroundColor: '#eef5f5' }}
    >
      <Container
        maxWidth={'lg'}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="26px"
        >
          <Box display="flex" alignItems="center">
            <Typography
              fontSize={20}
              fontFamily="Roboto"
              fontWeight={700}
              marginRight="8px"
            >
              Manage 64 people added to this project
            </Typography>
            <Tooltip title="Show info here">
              <Info color="rgba(0, 0, 0, 0.54)" width={20} height={20} />
            </Tooltip>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#00a4a9',
            }}
            disableRipple
          >
            <Typography
              fontSize={14}
              fontWeight={500}
              color="#fafafa"
              marginRight="10px"
            >
              ADD PEOPLE
            </Typography>
            <Add color="white" width={12} height={12} />
          </Button>
        </Box>
        <Paper
          component="div"
          sx={{
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingTop: '16px',
            paddingBottom: '16px',
            marginBottom: '49px',
          }}
        >
          <Box
            maxWidth="573px"
            width="100%"
            margin="16px 0px 16px 16px"
            padding="8px 14px"
            height="40px"
            sx={{
              border: 'solid 1px rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              type="button"
              sx={{ p: '0px' }}
              aria-label="search"
              disabled
            >
              <Search color="rgba(0, 0, 0, 0.54)" width={24} height={24} />
            </IconButton>
            <InputBase
              placeholder="Search by Name, Job Role, Supplier, or Certchain ID"
              sx={{ ml: 1, flex: 1 }}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            marginLeft="16px"
            marginBottom="16px"
          >
            <Filter color="rgba(0, 0, 0, 0.87)" width={24} height={24} />
            <Typography marginLeft="11px" fontSize={16} fontWeight={400}>
              Add filters
            </Typography>
          </Box>
          <Box
            width="100%"
            height="56px"
            sx={{ backgroundColor: '#00a4a9' }}
          ></Box>
          <DataGrid
            sx={{ border: 0, flex: 1, display: 'block', marginBottom: '16px' }}
            getRowHeight={() => 'auto'}
            rows={workforceList}
            columns={assignColumns}
            rowSelectionModel={workforceList.map((r) => r.id)}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </Paper>
      </Container>
    </Box>
  );

  if (isInitialState) {
    return renderInitialStateScreen();
  }

  return screenView === 'assign' ? renderAssignScreen() : renderResultsScreen();
};

export default Workforce;
