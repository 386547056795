//@ts-nocheck

import { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button,
  Modal,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import ProjectStatusChip from '../components/ProjectStatusChip';
import ProjectType from '../enums/ProjectType';
import locationList from '../data/Location.json';

import { ReactComponent as Apartment } from '../assets/icons/apartment.svg';
import { ReactComponent as Inventory } from '../assets/icons/inventory.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as People } from '../assets/icons/people.svg';
import { ReactComponent as MoreVert } from '../assets/icons/moreVert.svg';
import { ReactComponent as Add } from '../assets/icons/add.svg';

import { useAppDispatch, useAppSelector } from '../store';
import { addProject } from '../store/project';

const Portfolio = () => {
  const dispatch = useAppDispatch();
  const { appState } = useAppSelector((state) => state.app);
  const { projectList, currentProject } = useAppSelector(
    (state) => state.project
  );

  const [activeView, setActiveView] = useState(ProjectType.APARTMENT);
  const [modalOpen, setModalOpen] = useState(false);
  const [enableNotificationCard, setEnableNotificationCard] = useState(true);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const openChatWidget = useCallback(() => {
    if (window.Captivate) {
      console.log('captivate', window.Captivate);
      window.Captivate.openWidget();
      window.Captivate.createConversation();

      if (appState === 'part1') {
        setTimeout(() => {
          window.Captivate.sendActionToBot({ id: 'new_project', loader: true });
        }, 1000);
      } else if (appState === 'part2') {
        setEnableNotificationCard(false);
        setTimeout(() => {
          // window.Captivate.sendActionToBot({
          //   id: 'block_access',
          //   loader: true,
          // });
          window.Captivate.sendActionToBot({
            id: 'ClickViewButton',
            loader: true,
          });
        }, 1000);
      }
    }
  }, [appState]);

  const triggerNewProjectBotAction = useCallback(() => {
    dispatch(addProject(currentProject));
    enqueueSnackbar('Project has been successfully created', {
      variant: 'success',
      style: {
        backgroundColor: '#ebf2eb',
        color: '#1e4620',
      },
    });

    setTimeout(() => {
      window.Captivate.sendActionToBot({
        id: 'new_project_created_success_ui',
        loader: true,
      });
    }, 1000);
  }, [currentProject, dispatch]);

  const triggerOpenProjectWorkspaceAction = useCallback(() => {
    setModalOpen(true);
    setTimeout(() => {
      navigate('/project/9482a20e-7876-42fd-a6b5-48380417dee4/workforce');
    }, 1500);
  }, [navigate]);

  const triggerQueryGoldenThreadAction = useCallback(() => {
    setModalOpen(true);
    setTimeout(() => {
      navigate('/project/9482a20e-7876-42fd-a6b5-48380417dee4/compliance/all');
    }, 1500);
  }, [navigate]);

  useEffect(() => {
    window.Captivate.onActionReceived((response: any) => {
      console.log('response', response);
      switch (response[0].title) {
        case 'new_project_created_success_bot':
          triggerNewProjectBotAction();
          break;
        case 'open_project_workspace':
          triggerOpenProjectWorkspaceAction();
          break;
        case 'QueryGoldenThread':
          triggerQueryGoldenThreadAction();
          break;
        default:
          break;
      }
    });
  }, [triggerNewProjectBotAction, triggerOpenProjectWorkspaceAction]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            marginBottom="-8px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography
                fontSize={20}
                fontFamily="Roboto"
                fontWeight={500}
                marginRight="8px"
              >
                Manage your Building Portfolio
              </Typography>
              <Tooltip title="Show info here">
                <Info color="rgba(0, 0, 0, 0.54)" width={20} height={20} />
              </Tooltip>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              flexDirection={isMobileSize ? 'column' : 'row'}
            >
              <IconButton
                disableRipple
                size="small"
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: 0,
                  ...(activeView === ProjectType.APARTMENT && {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  }),
                }}
                onClick={() => setActiveView(ProjectType.APARTMENT)}
              >
                <Apartment color="rgba(0, 0, 0, 0.87)" height={18} width={18} />
              </IconButton>
              <IconButton
                disableRipple
                size="small"
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: 0,
                  ...(activeView === ProjectType.INVENTORY && {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  }),
                }}
                onClick={() => setActiveView(ProjectType.INVENTORY)}
              >
                <Inventory color="rgba(0, 0, 0, 0.87)" height={18} width={18} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        {projectList.map((project) => (
          <Grid key={project.id} item xs={12} sm={6} md={6} lg={4}>
            <Card
              elevation={1}
              sx={{
                height: '100%',
                minHeight: '197px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader
                sx={{ pb: '10px' }}
                title={
                  <Link
                    to={`/project/${project.id}/compliance/all`}
                    style={{ color: 'inherit', textDecoration: 'inherit' }}
                  >
                    <Typography
                      fontSize={16}
                      fontFamily="Roboto"
                      fontWeight={400}
                    >
                      {project.name}
                    </Typography>
                  </Link>
                }
                action={
                  <IconButton
                    disableRipple
                    aria-label="settings"
                    sx={{
                      backgroundColor: '#f5f5f5',
                      width: '28px',
                      height: '28px',
                    }}
                  >
                    <MoreVert
                      color="rgba(0, 0, 0, 0.54)"
                      width={20}
                      height={20}
                    />
                  </IconButton>
                }
              />
              <CardContent sx={{ pt: 0, flex: 1 }}>
                <List sx={{ pt: 0 }}>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: '12px', mr: '10px' }}>
                      <Apartment
                        color="rgba(0, 0, 0, 0.26)"
                        height={12}
                        width={12}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          fontSize={12}
                          fontFamily="Roboto"
                          fontWeight={500}
                        >
                          {
                            locationList.find(
                              (location) => location.id === project.locationId
                            )?.name
                          }
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: '12px', mr: '10px' }}>
                      <People
                        color="rgba(0, 0, 0, 0.26)"
                        height={12}
                        width={12}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          fontSize={12}
                          fontFamily="Roboto"
                          fontWeight={500}
                        >
                          {project.peopleAssigned}
                          <Typography
                            marginLeft="5px"
                            component="span"
                            fontSize={12}
                            fontWeight={400}
                            color="rgba(0, 0, 0, 0.6)"
                          >
                            people assigned
                          </Typography>
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  height: '56px',
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  padding: '16px 15px',
                }}
              >
                <ProjectStatusChip project={project} />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {enableNotificationCard && appState === 'part2' ? (
        <Box
          position="fixed"
          bottom="110px"
          right="20px"
          padding="20px"
          display="flex"
          maxWidth="500px"
          width="100%"
          sx={{
            backgroundColor: '#FFF',
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 5px 20px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Avatar
                sx={{ border: '1px solid lightgray' }}
                src="https://res.cloudinary.com/captivateioltd/image/upload/v1689854760/captivate/gourfyrmotz1ukzfronj.gif"
                size="small"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography fontSize={16} fontWeight={400}>
                Francis, I have urgent workforce issue on the project One
                Pinnacle Tower for which I have taken automated actions. Please
                review.
              </Typography>
              <Box
                marginTop="20px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Just Now
                </Typography>
                <Button
                  variant="outlined"
                  onClick={openChatWidget}
                  size="small"
                  disableRipple
                  sx={{
                    border: '1px solid #00a4a9',
                    color: '#00a4a9',
                  }}
                >
                  View
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}

      <Button
        sx={{
          position: 'fixed',
          bottom: '20px',
          margin: 0,
          right: '180px',
          borderRadius: '24px',
          backgroundColor: '#00a4a9',
          width: '170px',
          height: '50px',
          boxSizing: 'border-box',
        }}
        disableRipple
        variant="contained"
        onClick={openChatWidget}
      >
        <Add color="white" width={14} height={14} />
        <Typography
          marginLeft="13px"
          fontSize={15}
          fontWeight={500}
          fontFamily="Roboto"
          letterSpacing={0.43}
        >
          New Project
        </Typography>
      </Button>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="Opening Project Workspace"
      >
        <Box
          style={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobileSize ? '80%' : 400,
            boxShadow: 24,
            p: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: '#FFF',
            height: '240px',
            outline: 'none',
          }}
        >
          <Typography fontSize={20} fontWeight={700} marginBottom="40px">
            Opening Project Workspace
          </Typography>
          <CircularProgress sx={{ color: '#00a4a9' }} />
        </Box>
      </Modal>
    </Container>
  );
};

export default Portfolio;
