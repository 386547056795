import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

import BalfourBeauty from '../assets/icons/balfour-beatty.png';
import { ReactComponent as Workforce } from '../assets/icons/workforce.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as OpenIn } from '../assets/icons/openin.svg';
import { ReactComponent as Chat } from '../assets/icons/chat.svg';
import { ReactComponent as ChevronRight } from '../assets/icons/chevronRight.svg';
import { ReactComponent as Widgets } from '../assets/icons/widgets.svg';
import { ReactComponent as Person } from '../assets/icons/person.svg';
import { ReactComponent as Memory } from '../assets/icons/memory.svg';
import { useAppDispatch, useAppSelector } from '../store';
import { setCurrentCase } from '../store/case';
import { useEffect, useState } from 'react';
import CaseStatus from '../enums/CaseStatus';

const CaseEscalation = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { currentCase } = useAppSelector((state) => state.case);
  const [loading, setLoading] = useState(false);

  const [caseStatus, setCaseStatus] = useState(CaseStatus.ACTIVE);

  useEffect(() => {
    if (currentCase) {
      setLoading(true);
      setTimeout(() => setLoading(false), 1000);
    }
  }, [currentCase]);

  if (!currentCase) {
    return null;
  }

  return (
    <>
      <Toolbar sx={{ pt: 2 }}>
        <Stack direction="column" gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            <Workforce
              color={theme.palette.primary.main}
              width={24}
              height={24}
            />
            <Typography fontSize={20} fontWeight={500}>
              {currentCase!.name}
            </Typography>
            <IconButton
              onClick={() => dispatch(setCurrentCase(undefined))}
              disabled={loading}
            >
              <Close color="rgba(0, 0, 0, 0.6)" width={24} height={24} />
            </IconButton>
          </Stack>
          <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
            EC6789031&nbsp;&nbsp;Compliance alert
          </Typography>
        </Stack>
      </Toolbar>
      <Divider />
      {loading ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          paddingTop={20}
        >
          <Memory color="action" width={24} height={24} />
          <Typography variant="body1">Certchain AI is querying</Typography>
          <CircularProgress />
          <Typography variant="caption">50%</Typography>
        </Stack>
      ) : (
        <Box flex="1" sx={{ overflow: 'auto' }}>
          <Stack direction="column" gap={3} padding="24px">
            <Stack direction="column" gap={1}>
              <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                Case Status
                <Info
                  style={{ marginLeft: '10px' }}
                  width={14}
                  height={14}
                  color={theme.palette.primary.main}
                />
              </Typography>
              <Box
                width="91px"
                height="36px"
                padding="6px 14.5px"
                sx={{
                  backgroundColor: '#00a4a9',
                  color: 'white',
                  boxShadow:
                    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                  borderRadius: '4px',
                }}
              >
                OPEN
              </Box>
            </Stack>

            <FormControl>
              <Typography
                variant="caption"
                color="rgba(0, 0, 0, 0.6)"
                marginBottom="13px"
              >
                Case Owner
                <Info
                  style={{ marginLeft: '10px' }}
                  width={14}
                  height={14}
                  color={theme.palette.primary.main}
                />
              </Typography>
              <Stack direction="row" gap={2}>
                <Box
                  height="40px"
                  maxWidth="425px"
                  width="100%"
                  sx={{
                    borderRadius: '4px',
                    border: 'solid 1px rgba(0, 0, 0, 0.23)',
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <Search
                      color="rgba(0, 0, 0, 0.54)"
                      width={24}
                      height={24}
                    />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By Name"
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                </Box>
                <Button variant="text" sx={{ color: '#00a4a9' }} disableRipple>
                  ASSIGN TO ME
                </Button>
              </Stack>
            </FormControl>

            <Divider />

            <Typography
              variant="caption"
              color="rgba(0, 0, 0, 0.6)"
              marginBottom="20px"
            >
              Description
              <Info
                style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                width={14}
                height={14}
                color={theme.palette.primary.main}
              />
            </Typography>

            <Stack direction="row" gap={1}>
              <Avatar
                sx={{ border: '1px solid lightgray' }}
                src="https://res.cloudinary.com/captivateioltd/image/upload/v1689854760/captivate/gourfyrmotz1ukzfronj.gif"
              />
              <Stack direction="column" gap={1}>
                <Typography fontSize={16}>
                  Golden Fred
                  <OpenIn
                    style={{ marginLeft: '10px' }}
                    color="rgba(0, 0, 0, 0.6)"
                    height={12}
                    width={12}
                  />
                </Typography>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Certchain AI
                </Typography>
              </Stack>
            </Stack>

            <Typography fontSize={16}>
              This case was created by Francis Golder through Golden Fred AI.
              Francis Golder requested an escalation to legal and procurement
              resolver groups after the status of Aaron Johnson’s Supervisor
              passport became expired, due to not completing mandatory training
              within the allowed time.
            </Typography>

            <Button
              variant="outlined"
              sx={{
                border: '1px solid #00a4a9',
                color: '#00a4a9',
                width: '130px',
              }}
            >
              ASK FRED
              <Chat
                style={{ marginLeft: '8px' }}
                color="#00a4a9"
                height={16}
                width={16}
              />
            </Button>

            <Divider />

            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Case details and audit
              <Info
                style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                width={14}
                height={14}
                color={theme.palette.primary.main}
              />
            </Typography>

            <List>
              <ListItemButton disableRipple sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '4px',
                    }}
                  >
                    <Info width={16} height={16} color="#fff" />
                  </Box>
                </ListItemIcon>
                <ListItemText primary="Case Details" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="24px"
                  width="24px"
                  sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%' }}
                >
                  <ChevronRight />
                </Box>
              </ListItemButton>
              <ListItemButton disableRipple sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '4px',
                    }}
                  >
                    <Widgets width={16} height={16} color="#fff" />
                  </Box>
                </ListItemIcon>
                <ListItemText primary="Golden Thread Timeline" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="24px"
                  width="24px"
                  sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%' }}
                >
                  <ChevronRight />
                </Box>
              </ListItemButton>
              <ListItemButton disableRipple sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '4px',
                    }}
                  >
                    <Chat width={16} height={16} color="#fff" />
                  </Box>
                </ListItemIcon>
                <ListItemText primary="Golden Fred Chat Transcript" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="24px"
                  width="24px"
                  sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%' }}
                >
                  <ChevronRight />
                </Box>
              </ListItemButton>
            </List>

            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Linked cases and information
              <Info
                style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                width={14}
                height={14}
                color={theme.palette.primary.main}
              />
            </Typography>

            <List>
              <ListItemButton disableRipple sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '4px',
                    }}
                  >
                    <Workforce width={16} height={16} color="#fff" />
                  </Box>
                </ListItemIcon>
                <ListItemText primary="Certification issue – Aaron Johnson – Case WA–223402894" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="24px"
                  width="24px"
                  sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%' }}
                >
                  <ChevronRight />
                </Box>
              </ListItemButton>
              <ListItemButton disableRipple sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '4px',
                    }}
                  >
                    <Person width={16} height={16} color="#fff" />
                  </Box>
                </ListItemIcon>
                <ListItemText primary="Aaron Johnson – Profile" />
                <Stack direction="row" gap={2} justifyContent="space-between">
                  <Chip
                    sx={{
                      backgroundColor: '#d32f2f',
                      height: '24px',
                      minWidth: '92px',
                      span: {
                        paddingLeft: '1.5px',
                        paddingRight: '8px',
                      },
                    }}
                    label={
                      <Box display="flex" alignItems="center">
                        <Info color="#FFF" width={15} height={15} />
                        <Typography
                          marginLeft="5px"
                          color="#FFF"
                          fontSize={13}
                          fontWeight={400}
                          fontFamily="Roboto"
                        >
                          Active Risk (1)
                        </Typography>
                      </Box>
                    }
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="24px"
                    width="24px"
                    sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%' }}
                  >
                    <ChevronRight />
                  </Box>
                </Stack>
              </ListItemButton>
              <ListItemButton disableRipple sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <Box
                    component="img"
                    src={BalfourBeauty}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="32px"
                    height="32px"
                    sx={{
                      borderRadius: '4px',
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Aaron Johnson – Supervisor Passport" />
                <Stack direction="row" gap={2} justifyContent="space-between">
                  <Chip
                    sx={{
                      backgroundColor: '#d32f2f',
                      height: '24px',
                      minWidth: '92px',
                      span: {
                        paddingLeft: '1.5px',
                        paddingRight: '8px',
                      },
                    }}
                    label={
                      <Box display="flex" alignItems="center">
                        <Info color="#FFF" width={15} height={15} />
                        <Typography
                          marginLeft="5px"
                          color="#FFF"
                          fontSize={13}
                          fontWeight={400}
                          fontFamily="Roboto"
                        >
                          Expired
                        </Typography>
                      </Box>
                    }
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="24px"
                    width="24px"
                    sx={{ backgroundColor: '#f5f5f5', borderRadius: '50%' }}
                  >
                    <ChevronRight />
                  </Box>
                </Stack>
              </ListItemButton>
            </List>

            <Divider />

            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Case comments
              <Info
                style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                width={14}
                height={14}
                color={theme.palette.primary.main}
              />
            </Typography>

            <Stack direction="row" gap={2} marginBottom="70px">
              <Avatar src="https://www.himalmag.com/wp-content/uploads/2019/07/sample-profile-picture.png" />
              <InputBase
                sx={{
                  padding: '8px 13px',
                  height: '40px',
                  boxSizing: 'border-box',
                  flex: 1,
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                }}
                placeholder="Add comments"
              />
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default CaseEscalation;
