import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  appState: string;
}

const initialState: AppState = {
  appState: 'part1',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeAppState: (state, action: PayloadAction<string>) => {
      state.appState = action.payload;
    },
  },
});

export const { changeAppState } = appSlice.actions;

export default appSlice.reducer;
