import Case from '../interfaces/Case';

import caseList from '../data/Case.json';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CaseState {
  caseList: Case[];
  currentCase: Case | undefined;
}

const initialState: CaseState = {
  caseList: caseList.slice(1),
  currentCase: caseList.find(
    (item) => item.id === 'a67e90c7-bb1b-4572-a743-b2d972348120'
  ) as Case,
};

export const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    setCurrentCase: (state, action: PayloadAction<Case | undefined>) => {
      state.currentCase = action.payload;
    },
    addCase: (state, action: PayloadAction<Case>) => {
      state.caseList.unshift(action.payload);
    },
  },
});

export const { setCurrentCase, addCase } = caseSlice.actions;

export default caseSlice.reducer;
