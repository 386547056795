//@ts-nocheck

import { useState } from 'react';
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom';

import {
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Chip,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@mui/material';

import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';
import ProjectStatusChip from '../components/ProjectStatusChip';

import BalfourBeatty from '../assets/icons/balfour-beatty.png';
import { ReactComponent as WorkforceIcon } from '../assets/icons/workforce.svg';
import { ReactComponent as ChevronLeft } from '../assets/icons/chevronLeft.svg';
import { ReactComponent as Notifications } from '../assets/icons/notifications.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as Supplier } from '../assets/icons/supplier.svg';
import { ReactComponent as HealthAndSafety } from '../assets/icons/healthAndSafety.svg';
import { ReactComponent as ExpandMore } from '../assets/icons/expandMore.svg';
import { ReactComponent as ExpandLess } from '../assets/icons/expandLess.svg';
import { ReactComponent as Article } from '../assets/icons/article.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/checkCircle.svg';
import { ReactComponent as Menu } from '../assets/icons/menu.svg';

import caseList from '../data/Case.json';
import CaseStatus from '../enums/CaseStatus';

import { useAppSelector } from '../store';

const selectedStyle = {
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
};

const MainListItems = () => {
  const theme = useTheme();
  const { projectId } = useParams();
  const [openCompliance, setOpenCompliance] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const projectCaseList = caseList.filter(
    (value) => value.projectId === projectId
  );

  const handleClickCompliance = () => setOpenCompliance(!openCompliance);

  // TODO: find a prop in ListItemButton on how to navigate without this handler
  const handleComplianceNavigate = (caseStatus: CaseStatus) =>
    navigate(`/project/${projectId}/compliance/${caseStatus}`);

  return (
    <>
      <ListItemButton disableRipple onClick={handleClickCompliance}>
        <ListItemIcon>
          <HealthAndSafety
            color={theme.palette.primary.main}
            width={24}
            height={24}
          />
        </ListItemIcon>
        <ListItemText primary="Compliance" />
        {openCompliance ? (
          <ExpandLess color="rgba(0, 0, 0, 0.54)" width={24} height={24} />
        ) : (
          <ExpandMore color="rgba(0, 0, 0, 0.54)" width={24} height={24} />
        )}
      </ListItemButton>
      <Collapse in={openCompliance} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            selected={pathname.includes('compliance/all')}
            sx={{ pl: 4, ...selectedStyle }}
            onClick={() => handleComplianceNavigate(CaseStatus.ALL)}
          >
            <ListItemIcon>
              <Article
                color={theme.palette.primary.main}
                width={18}
                height={18}
              />
            </ListItemIcon>
            <ListItemText primary="All cases" />
            <Chip label={projectCaseList.length} color="primary" size="small" />
          </ListItemButton>
          <ListItemButton
            selected={pathname.includes('compliance/active')}
            sx={{ pl: 4, ...selectedStyle }}
            onClick={() => handleComplianceNavigate(CaseStatus.ACTIVE)}
          >
            <ListItemIcon>
              <Error color="#d32f2f" width={18} height={18} />
            </ListItemIcon>
            <ListItemText primary="Active" />
            <Chip
              label={
                projectCaseList.filter(
                  (value) => value.status === CaseStatus.ACTIVE
                ).length
              }
              color="primary"
              size="small"
            />
          </ListItemButton>
          <ListItemButton
            selected={pathname.includes('compliance/emerging')}
            sx={{ pl: 4, ...selectedStyle }}
            onClick={() => handleComplianceNavigate(CaseStatus.EMERGING)}
          >
            <ListItemIcon>
              <Warning color="#ed6c02" width={18} height={18} />
            </ListItemIcon>
            <ListItemText primary="Emerging" />
            <Chip
              label={
                projectCaseList.filter(
                  (value) => value.status === CaseStatus.EMERGING
                ).length
              }
              color="primary"
              size="small"
            />
          </ListItemButton>
          <ListItemButton
            selected={pathname.includes('compliance/resolved')}
            sx={{ pl: 4, ...selectedStyle }}
            onClick={() => handleComplianceNavigate(CaseStatus.RESOLVED)}
          >
            <ListItemIcon>
              <CheckCircle color="#4caf50" width={18} height={18} />
            </ListItemIcon>
            <ListItemText primary="Resolved" />
            <Chip
              label={
                projectCaseList.filter(
                  (value) => value.status === CaseStatus.RESOLVED
                ).length
              }
              color="primary"
              size="small"
            />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton
        disableRipple
        selected={pathname.includes('workforce')}
        sx={selectedStyle}
        onClick={() => navigate(`/project/${projectId}/workforce`)}
      >
        <ListItemIcon>
          <WorkforceIcon
            color={theme.palette.primary.main}
            width={24}
            height={24}
          />
        </ListItemIcon>
        <ListItemText primary="Workforce" />
      </ListItemButton>
      <ListItemButton
        disableRipple
        selected={pathname.includes('supplier')}
        sx={selectedStyle}
        onClick={() => navigate(`/project/${projectId}/supplier`)}
      >
        <ListItemIcon>
          <Supplier color={theme.palette.primary.main} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Suppliers" />
      </ListItemButton>
      <ListItemButton
        disableRipple
        selected={pathname.includes('settings')}
        sx={selectedStyle}
      >
        <ListItemIcon>
          <Settings color={theme.palette.primary.main} width={24} height={24} />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
    </>
  );
};

const DashboardProjectLayout = () => {
  const { currentProject } = useAppSelector((state) => state.project);

  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} isMobileSize={isMobileSize}>
        <Toolbar sx={{ pr: '24px', height: '94px' }}>
          <IconButton
            disableRipple
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && !isMobileSize && { display: 'none' }),
            }}
          >
            <Menu color="white" width={24} height={24} />
          </IconButton>
          <Typography
            component="h1"
            fontSize={20}
            fontFamily="Roboto"
            fontWeight={500}
            lineHeight={1.6}
            letterSpacing={0.15}
            color="#FFF"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Workforce
            <Box display="flex" alignItems="center">
              <Typography fontSize={16} fontWeight={400} marginRight="16px">
                {currentProject.name}
              </Typography>
              <ProjectStatusChip project={currentProject} />
            </Box>
          </Typography>
          <IconButton color="inherit">
            <Badge
              badgeContent={1}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: '#f44336',
                },
              }}
            >
              <Notifications color="white" width={24} height={24} />
            </Badge>
          </IconButton>
          <Box marginLeft="40px" display="flex">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              marginRight="16px"
            >
              <Typography
                fontSize={16}
                fontFamily="Roboto"
                color="#FFF"
                letterSpacing={0.15}
                fontWeight={400}
              >
                Francis Golden
              </Typography>
              <Typography
                fontSize={12}
                fontFamily="Roboto"
                color="#FFF"
                letterSpacing={0.4}
                fontWeight={400}
              >
                Director
              </Typography>
            </Box>
            <Avatar
              alt="Francis Golden"
              src="https://www.himalmag.com/wp-content/uploads/2019/07/sample-profile-picture.png"
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        variant={isMobileSize ? 'temporary' : 'permanent'}
        open={open}
        elevation={24}
        onClose={toggleDrawer}
        isMobileSize={isMobileSize}
      >
        <Toolbar
          sx={{
            px: [1],
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component="img"
            src={BalfourBeatty}
            width={40}
            height={40}
            onClick={() => {
              if (window.Captivate) {
                window.Captivate.closeWidget();
              }
              navigate('/portfolio');
            }}
            sx={{ cursor: 'pointer' }}
          />
          <IconButton disableRipple onClick={toggleDrawer}>
            <ChevronLeft color="rgba(0, 0, 0, 0.54)" width={24} height={24} />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? '#fff' : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar sx={{ height: '94px' }} />
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardProjectLayout;
